import { ChartOptions, Interaction } from "chart.js"
import "chart.js/auto"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { datalabels } from "../plugins/datalabels"
import { highlightLegendData, hoverPlugin, resetChart } from "../plugins/hover"
import { getLabelPoint } from "../plugins/label-diagonal"
import { LABEL_FONT_SIZE, LABEL_WIDTH, wrapText } from "../utils"

const LineChart: React.FC<any> = ({ data }) => {
    const { form } = useChart()
    const dctx = useDataContext()
    const { chart } = form.values
    const { type, yAxis, labelwidth = LABEL_WIDTH } = chart
    const unit = utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space)

    const isStacked = yAxis.grouped === "stack"

    // Chart options
    const options: ChartOptions<"line"> = {
        ...form.values.chart.chartjs.options,
        animation: false,
        indexAxis: "x",
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
        },
        clip: false, // point crop
        scales: {
            y: {
                stacked: isStacked,
                type: yAxis.logscale ? "logarithmic" : "linear",
                min: yAxis.min ?? undefined,
                max: yAxis.max ?? undefined,
                suggestedMin: dctx.getAxisLimit("yAxis", "min"),
                suggestedMax: dctx.getAxisLimit("yAxis", "max"),
                ticks: {
                    display: !chart.yAxis.hideAxislabels,
                    callback: (value: any) => {
                        const label = `${utils.displayCompact(
                            value as number,
                            unit,
                            yAxis.precision,
                            yAxis.prefix,
                        )}`
                        return !yAxis.logscale || Math.log10(value) % 1 === 0 ? label : ""
                    },
                },
                grid: {
                    display: !chart.yAxis.hideGridlines,
                    color: !yAxis.logscale
                        ? undefined
                        : function (context) {
                              if (Math.log10(context.tick.value) % 1 !== 0) return ""
                              return "rgba(0,0,0,0.1)"
                          },
                },
                border: {
                    display: false,
                },
            },
            x: {
                type: "linear",
                min: dctx.time.min,
                max: dctx.time.max,
                ticks: {
                    display: chart.categorylabels,
                    format: {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        useGrouping: false,
                    },
                    //includeBounds: false,
                },
                grid: {
                    display: chart.categorylabels,
                    drawOnChartArea: false,
                    drawTicks: true,
                },
                border: {
                    display: chart.categorylabels,
                },
            },
        },
        layout: {
            padding: {
                right: chart.datalabels ? labelwidth * (LABEL_FONT_SIZE * 0.6) : 0,
            },
        },
        onHover: (e, active, chart) => {
            const nearestElement = Interaction.modes.dataset(chart, e, { intersect: false })
            hoverPlugin(e, nearestElement, chart, type)
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            datalabels: {
                display: chart.datalabels,
                formatter: (label) => wrapText(label, labelwidth),
                listeners: {
                    enter: (ctx) => {
                        highlightLegendData(ctx.datasetIndex, type)
                    },
                    leave: () => resetChart(type),
                },
            },
            tooltip: {
                position: "nearest",

                itemSort: isStacked ? undefined : (a, b) => b.parsed.y - a.parsed.y,
                footerFont: {
                    weight: "normal",
                },
                footerColor: "#575757",
                callbacks: {
                    title: (ctx) => {
                        const value = ctx[0].parsed.x
                        return chart.projectionStart && value > chart.projectionStart
                            ? `${value} (Projection)`
                            : `${value}`
                    },
                    footer: (tooltip) => {
                        if (!tooltip.length || !tooltip[0]?.chart?.legend?.legendItems) return []
                        const allLegends =
                            tooltip[0].chart.legend?.legendItems?.map((item) => item.text) || []
                        const foundLegends = tooltip.map((item) => item.dataset.label)
                        const missingLegends = allLegends.filter(
                            (legend) => !foundLegends.includes(legend),
                        )
                        return missingLegends?.length
                            ? missingLegends.map((legend) => `${legend}: No data`)
                            : []
                    },
                    label: (ctx: any) => {
                        const label = ctx.dataset.label
                        return `${label}: ${utils.displayCompact(
                            ctx.parsed.y,
                            unit,
                            yAxis.precision,
                            yAxis.prefix,
                        )}`

                        // const scale = ctx.chart.scales.y
                        // const mouseY = ctx.chart.tooltip._eventPosition.y
                        // const pointY = scale.getPixelForValue(ctx.parsed.y)
                        // const isHovered = Math.abs(mouseY - pointY) < 5

                        // return isHovered ? `<b>${labelText}</b>` : labelText
                    },
                    labelColor: (ctx) => {
                        const datasetColor = ctx.dataset.backgroundColor as string
                        return {
                            backgroundColor:
                                chart.projectionStart && ctx.parsed.x > chart.projectionStart
                                    ? (getLabelPoint(datasetColor) as CanvasPattern)
                                    : datasetColor,
                            borderColor: ctx.dataset.borderColor as string,
                        }
                    },
                },
            },
        },
    }

    return <Chart type="line" options={options} data={data} plugins={[datalabels]} />
}

export default LineChart
