import { ChartOptions, PointElement } from "chart.js"
import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { hoverPlugin } from "../plugins/hover"
import { LABEL_FONT_SIZE, wrapText } from "../utils"

const Dotplot: React.FC<any> = ({ data }) => {
    const { form } = useChart()
    const { chart } = form.values
    const { yAxis, labelwidth, type } = chart
    const dctx = useDataContext()
    const unit = utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space)
    const yAxisMax = dctx.getAxisLimit("yAxis", "max") ?? dctx.yAxis.max

    // half the elements are bars, rest are dots
    const dotElements = data.datasets.length / 2

    // Chart options
    const options: ChartOptions<"bar" | "scatter"> = {
        ...chart.chartjs.options,
        indexAxis: "y",
        scales: {
            y: {
                stacked: true,
                ticks: {
                    display: chart.categorylabels,
                    callback: (i: any) => {
                        return wrapText(data.labels[i], labelwidth)
                    },
                },
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: false,
                type: yAxis.logscale ? "logarithmic" : "linear",
                min: yAxis.min ?? undefined,
                max: yAxis.max ?? undefined,
                suggestedMin: dctx.getAxisLimit("yAxis", "min"),
                suggestedMax: dctx.getAxisLimit("yAxis", "max"),
                ticks: {
                    display: !chart.yAxis.hideAxislabels,
                    callback: (v) => {
                        const label = utils.dataLabelFormatter(Number(v), unit, yAxis)
                        return !yAxis.logscale || Math.log10(Number(v)) % 1 === 0 ? label : ""
                    },
                },
                grid: {
                    display: !chart.yAxis.hideGridlines,
                    color: !yAxis.logscale
                        ? undefined
                        : function (context) {
                              if (Math.log10(context.tick.value) % 1 !== 0) return ""
                              return "rgba(0,0,0,0.1)"
                          },
                },
                border: {
                    display: false,
                },
            },
        },
        animation: false,
        onHover: (e, active, chart) => {
            const element = active.filter((el) => el.element instanceof PointElement)
            hoverPlugin(e, element, chart, type)
        },
        layout: {
            padding: {
                right: yAxisMax
                    ? utils.dataLabelFormatter(yAxisMax, unit + "m", yAxis).length *
                      LABEL_FONT_SIZE *
                      0.6
                    : 30,
            },
        },
        plugins: {
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            legend: {
                display: false,
            },
            tooltip: {
                intersect: false,
                mode: "y",
                filter: (ctx) => {
                    // show tooltip only for dots
                    return ctx.dataset.type === "scatter"
                },
                callbacks: {
                    title: (ctx) => {
                        return ctx[0]?.label
                    },
                    label: (ctx) => {
                        const label = dctx.getDisplayLabel("yAxis", ctx.dataset.label)
                        return `${label}: ${utils.dataLabelFormatter(ctx.parsed.x, unit, yAxis)}`
                    },
                },
            },
            datalabels: {
                align: "end",
                anchor: "end",
                formatter: (v, ctx) =>
                    `${utils.displayCompact(
                        v,
                        yAxis.hideInLabel ? "" : unit,
                        yAxis.precision,
                        yAxis.prefix,
                    )}`,
                clamp: true,
                display: (ctx: any) => {
                    const hoveredElement = ctx.chart._active?.[0]
                    if (hoveredElement && hoveredElement.element instanceof PointElement) {
                        // datalabels are visible for bars instead of dots
                        // we show bar label when hovered, by subtracting the number of dot datasets
                        return hoveredElement.datasetIndex === ctx.datasetIndex - dotElements
                    }
                    return chart.datalabels ? "auto" : false
                },
                font: {
                    size: LABEL_FONT_SIZE,
                },
            },
        },
    }

    return <Chart type="bar" options={options} data={data} plugins={[ChartDataLabels]} />
}

export default Dotplot
